<template>
    <v-col cols="12" class="d-flex justify-center">
        <v-row>
            <v-col>
                <v-card rounded="xl" outlined elevation="5" :color="color" width="100%">
                    <v-card-text>
                        <v-row class="mx-auto">
                            <v-col cols="12" lg="4" class="text-h5 d-flex justify-center justify-lg-center white--text">
                                <v-icon size="30" color="white">mdi-wallet</v-icon> &nbsp; <strong>Balances</strong>
                            </v-col>
                        </v-row>
                        <v-row class="mx-auto">
                            <v-col cols="12" lg="4" class="text-h5 d-flex justify-center justify-lg-center white--text">
                                <v-container>
                                    <v-row class="d-flex justify-center mb-2">
                                        <strong>Credit</strong> <br>
                                    </v-row>
                                    <v-row class="d-flex justify-center mb-2">

                                        {{
                                                Intl.NumberFormat("fr").format((credit_cdf_amount).toFixed(2))
                                        }} CDF
                                    </v-row>
                                    <v-row class="d-flex justify-center">

                                        {{
                                                Intl.NumberFormat("fr").format((credit_usd_amount).toFixed(2))
                                        }} USD
                                    </v-row>
                                </v-container>
                            </v-col>
                            <v-col cols="12" lg="4" class="text-h5 d-flex justify-center justify-lg-center white--text">
                                <v-container>
                                    <v-row class="d-flex justify-center mb-2">
                                        <strong>Debit</strong> <br>
                                    </v-row>
                                    <v-row class="d-flex justify-center mb-2">

                                        {{
                                                Intl.NumberFormat("fr").format(debit_cdf_amount.toFixed(2))
                                        }} CDF


                                    </v-row>
                                    <v-row class="d-flex justify-center">
                                        {{
                                                Intl.NumberFormat("fr").format((debit_usd_amount).toFixed(2))
                                        }} USD
                                    </v-row>
                                </v-container>
                            </v-col>
                            <v-col cols="12" lg="4" class="text-h5 d-flex justify-center justify-lg-center white--text">
                                <v-container>
                                    <v-row class="d-flex justify-center mb-2">
                                        <strong>Total</strong> <br>
                                    </v-row>
                                    <v-row class="d-flex justify-center mb-2">
                                        {{
                                                Intl.NumberFormat("fr").format((credit_cdf_amount +
                                                    debit_cdf_amount).toFixed(2))
                                        }} CDF

                                    </v-row>
                                    <v-row class="d-flex justify-center">

                                        {{
                                                Intl.NumberFormat("fr").format((credit_usd_amount +
                                                    debit_usd_amount).toFixed(2))
                                        }} USD
                                    </v-row>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
export default {
    props: ["debit_cdf_amount", "debit_usd_amount", "credit_cdf_amount", "credit_usd_amount", "title", "color"],
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
</style>